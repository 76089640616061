import classNames from 'classnames';

const NavBarMenu = ({Element, className, children, active, ...props}) => (
    <Element className={classNames('menu', className, {'is-active': active})} {...props}>
        {children}
    </Element>
);

NavBarMenu.defaultProps = {
    Element: 'div',
};

export default NavBarMenu;
