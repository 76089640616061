import _ from 'utils/i18n';

import BaseFooter, {MenuItem, MenuLink, Privacy, Imprint} from 'components/Footer';
import LanguageSwitcher from 'components/LanguageSwitcher';
import {URL as RegisterYourStoreURL} from 'components/RegisterYourStore';

import NewsletterSignUp from './NewsletterSignUp';
import SpectrMagazine from './SpectrMagazine';

const RegisterYourStoreLabel = _('Register Your store');

const Footer = () => (
    <BaseFooter>
        <NewsletterSignUp />
        <SpectrMagazine />
        <MenuItem Element="div">
            <LanguageSwitcher />
        </MenuItem>
        <MenuLink to={RegisterYourStoreURL}>{RegisterYourStoreLabel}</MenuLink>
        <Privacy />
        <Imprint />
    </BaseFooter>
);

export default Footer;
