import {useContext, useEffect, useMemo, useRef, useState} from 'react';
import {createPortal} from 'react-dom';

import {GoogleMapContext} from './GoogleMap';

const Control = ({children, position}) => {
    const [mounted, setMounted] = useState(false);
    const positionRef = useRef(position);
    const container = useMemo(() => {
        const node = document.createElement('div');
        node.classList.add('gm-control-container');
        return node;
    }, []);

    const {addControl, removeControl} = useContext(GoogleMapContext);

    useEffect(() => {
        addControl(container, position);
        setMounted(true);
        return () => {
            removeControl(container, position);
        };
    }, []);

    useEffect(() => {
        if (mounted) {
            if (positionRef.current) {
                removeControl(container, positionRef.current);
            }
            addControl(container, position);
            positionRef.current = position;
        }
    }, [position]);

    return mounted && createPortal(children, container);
};

export default Control;
