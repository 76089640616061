import BaseLayout from 'components/BaseLayout';

import Header from 'main-site/components/Header';
import Footer from 'main-site/components/Footer';
import TrackingMessage from 'main-site/components/TrackingMessage';

const Layout = props => (
    <BaseLayout {...props} Header={Header}>
        <Footer />
        <TrackingMessage />
    </BaseLayout>
);

export default Layout;
