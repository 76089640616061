import {Link} from 'react-router-dom';

import _ from 'utils/i18n';
import {buildURL} from 'utils/url';

import {Opticians} from 'selectra/urls';

import {TextContent} from 'components/Content';
import {useUserStore} from 'components/UserContext';

const Message = _('Are you a store owner?');
const Label = _('Register for free');

export const URL = buildURL(Opticians, 'register');

const RegisterYourStore = () => {
    const store = useUserStore();

    if (store) return null;

    return (
        <TextContent>
            <div className="store-owner is-centered">
                <p>{Message}</p>
                <Link className="button register-store" to={URL}>
                    {Label}
                </Link>
            </div>
        </TextContent>
    );
};

export default RegisterYourStore;
