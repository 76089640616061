import {useMemo} from 'react';
import {Helmet} from 'react-helmet';

import {useNoIndex} from 'selectra/hooks/hreflang';
import {Settings, useSetting} from 'selectra/settings';

const NoIndex = 'noindex';

export const useRobots = (defaultNoIndex = false) => {
    const defaultRobots = useSetting(Settings.robots);
    const noIndex = useNoIndex(defaultNoIndex);

    return useMemo(
        () => {
            let robots = defaultRobots || [];

            if (noIndex && !robots.includes(NoIndex)) {
                robots = [NoIndex, ...robots];
            }

            return robots.join(',') || 'all';
        },
        [noIndex, defaultRobots],
    );
};

export const Robots = ({content}) => (
    <Helmet>
        <meta name="robots" content={content} />
    </Helmet>
);

const RobotsController = ({noIndex}) => {
    const robots = useRobots(noIndex);

    return <Robots content={robots} />;
};

export default RobotsController;
