var React = require('react');

function IconLocationSvg (props) {
    return React.createElement("svg",props,React.createElement("path",{"d":"M29.068.262A28 28 0 0 0 .25 28.25c0 15.5 28 44 28 44s28-28.5 28-44A28 28 0 0 0 29.068.262zM28.25 13.25a13 13 0 0 1 13 13 13 13 0 0 1-13 13 13 13 0 0 1-13-13 13 13 0 0 1 13-13z"}));
}

IconLocationSvg.defaultProps = {"className":"icon location","viewBox":"-1 -1 58 74"};

module.exports = IconLocationSvg;

IconLocationSvg.default = IconLocationSvg;
