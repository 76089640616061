var React = require('react');

function IconHeartSvg (props) {
    return React.createElement("svg",props,React.createElement("path",{"d":"M62.264.25a21 21 0 0 0-20.5 16.5 21 21 0 0 0-20.5-16.5c-11.6 0-21.4 9.4-21 21 1 26 34 33 42 45 7-12 40-19 41-45 .5-11.6-9.4-21-21-21z"}));
}

IconHeartSvg.defaultProps = {"className":"icon heart","viewBox":"-1 -1 85 68"};

module.exports = IconHeartSvg;

IconHeartSvg.default = IconHeartSvg;
