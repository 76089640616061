import Logo from 'images/logo.svg.react';

import {cloneElement} from 'react';
import {Link} from 'react-router-dom';
import classNames from 'classnames';

import _ from 'utils/i18n';

import {useURL} from 'selectra/hooks';

import {TextContent} from 'components/Content';

import NavBarItem from './NavBarItem';
import NavBarToggle, {useBurgerMenu} from './NavBarToggle';

const Title = _('FAVR');

const NavBar = ({className, burger, children}) => {
    const [active, toggleActive] = useBurgerMenu();

    return (
        <nav className={classNames('navbar', className)} onClick={active ? toggleActive : null}>
            <div className="navbar-container">
                <div className="section">
                    <TextContent>
                        <div className="logo" itemScope itemType="https://schema.org/Organization">
                            <meta itemProp="name" content="FAVR Specs" />
                            <NavBarItem Element={Link} to={useURL('/')}>
                                <Logo itemProp="logo" className="brand" title={Title} />
                            </NavBarItem>
                        </div>
                        {burger && <NavBarToggle onClick={active ? null : toggleActive} />}
                        {cloneElement(children, {active})}
                    </TextContent>
                </div>
            </div>
        </nav>
    );
};

NavBar.defaultProps = {
    burger: false,
};

export default NavBar;
