import {createContext, useContext} from 'react';

const SingletonContext = createContext(null);

export const useSingleton = selector => {
    const singleton = useContext(SingletonContext);

    return selector ? selector(singleton) : singleton;
};

export const withSingleton = (Component, selector) => {
    const displayName = Component.displayName || Component.name;

    const Singleton = props => {
        const data = useSingleton(selector);

        return <Component {...props} {...data} />;
    };

    if (displayName) Singleton.displayName = `Singleton(${displayName})`;

    return Singleton;
};

export default SingletonContext.Provider;
