import {buildURL} from 'utils/url';
import language from 'utils/language';
import _, {interpolate} from 'utils/i18n';

import {Settings} from 'selectra//settings';

import BaseSite from 'components/BaseSite';

import Layout from './Layout';

import Routes from '../pages';

const TitleTemplate = '%s | FAVR';
const DefaultTitlePrefix = _('Premium Eyewear Finder');
const DefaultTitle = interpolate(TitleTemplate, [DefaultTitlePrefix], false);

const MainSite = () => (
    <BaseSite
        defaultTitle={DefaultTitle}
        titleTemplate={TitleTemplate}
        basename={buildURL(language)}
        Layout={Layout}
        routes={Routes}
        settings={{
            [Settings.showBrandProductFilters]: true,
            [Settings.showProductHostedBy]: true,
        }}
    />
);

export default MainSite;
