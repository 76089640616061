import _ from 'utils/i18n';
import language from 'utils/language';

import {Language} from 'selectra/constants';

import {MenuItem} from 'components/Footer';

const Label = _('Spectr Magazine');
const Link = 'https://spectr-magazine.com';
const URL = language === Language.German ? `${Link}/de/` : Link;

const SpectrMagazine = () => <MenuItem href={URL} target="_blank">{Label}</MenuItem>;

export default SpectrMagazine;
