import {useContext, useEffect, useRef, useState} from 'react';

import {GoogleMapContext} from './GoogleMap';

const Marker = ({position, label, draggable, onDrag}) => {
    const [mounted, setMounted] = useState(false);
    const marker = useRef(null);
    const {addMarker, removeMarker} = useContext(GoogleMapContext);
    const {maps: GoogleMaps} = global.google;

    useEffect(() => {
        marker.current = new GoogleMaps.Marker({position, label, draggable});
        addMarker(marker.current);
        setMounted(true);

        return () => removeMarker(marker.current);
    }, []);

    useEffect(
        () => {
            if (onDrag) {
                const handler = marker.current.addListener('dragend', onDrag);
                return () => GoogleMaps.event.removeListener(handler);
            }
        },
        [onDrag],
    );

    useEffect(() => {
        if (mounted) marker.current.setPosition(position);
    }, [position]);
    useEffect(() => {
        if (mounted) marker.current.setLabel(label);
    }, [label]);

    return null;
};

Marker.defaultProps = {
    draggable: false,
};

export default Marker;
