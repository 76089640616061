import _ from 'utils/i18n';
import {buildURL} from 'utils/url';
import language, {Language} from 'utils/language';
import {BlogActive as BlogFlag} from 'utils/settings';

export const BlogActive = Boolean(BlogFlag);
export const BlogURL = (blogURL => {
    if (language === Language.English) return blogURL;

    const a = document.createElement('a');

    a.href = blogURL;
    a.pathname = buildURL(language);

    return a.href;
})(window.BlogURL);

export {Language};

export const PaymentMethod = {
    Braintree: 'braintree',
    OnAccount: 'on-account',
};

export const StoreStatus = {
    Unregistered: 'unregistered',
    Registered: 'registered',
    Profile: 'profile',
    PremiumLight: 'premium-light',
    PremiumPlusLight: 'premium-plus-light',
    PremiumPlusLow: 'premium-plus-low',
    Premium: 'premium',
    PremiumPlus: 'premium-plus',
    PremiumPro: 'premium-pro',
};

StoreStatus.Basic = [
    StoreStatus.Unregistered,
    StoreStatus.Registered,
];

StoreStatus.Override = [
    StoreStatus.Profile,
    StoreStatus.PremiumLight,
    StoreStatus.PremiumPlusLight,
    StoreStatus.PremiumPlusLow,
];

StoreStatus.Subscription = [
    StoreStatus.Premium,
    StoreStatus.PremiumPlus,
    StoreStatus.PremiumPro,
];

export const PlanType = {
    Basic: 'basic',
    Premium: StoreStatus.Premium,
    PremiumPlus: StoreStatus.PremiumPlus,
    PremiumPro: StoreStatus.PremiumPro,
};

export const PlanOrder = [
    PlanType.Basic,
    PlanType.Premium,
    PlanType.PremiumPlus,
    PlanType.PremiumPro,
];

export const PlanName = {
    [PlanType.Basic]: _('Basic'),
    [PlanType.Premium]: _('Premium'),
    [PlanType.PremiumPlus]: _('Premium Plus'),
    [PlanType.PremiumPro]: _('Premium Pro'),
};

export const StatusOverrideName = {
    ...PlanName,
    [StoreStatus.Profile]: _('Profile'),
    [StoreStatus.PremiumLight]: _('Premium Light'),
    [StoreStatus.PremiumPlusLight]: _('Premium Plus Light'),
    [StoreStatus.PremiumPlusLow]: _('Premium Plus Low'),
};

export const PricesInCage = {
    None: 'none',
    FAVR: 'FAVR',
    Custom: 'custom',
};

export const BrandVisibility = {
    Highlight: 'highlight',
    Featured: 'featured',
    Premium: 'premium',
    Visible: 'visible',
    Hidden: 'hidden',
};

export const ProductType = {
    Eyeglasses: 'eye',
    Sunglasses: 'sun',
};

export const Site = {
    Main: 'main',
    Brand: 'brand',
    Optician: 'optician',
};

export const UserLocationSource = {
    IP: 'IP',
    URL: 'url',
    User: 'user',
    Default: 'default',
};

UserLocationSource.Inaccurate = [
    UserLocationSource.IP,
    UserLocationSource.URL,
    UserLocationSource.Default,
];
