import classNames from 'classnames';

import {useBodyClass} from 'selectra/hooks/dom';

import NavBar from './NavBar';
import NavBarItem from './NavBarItem';
import NavBarMenu from './NavBarMenu';
import NavBarPull from './NavBarPull';
import CurrentLocationItem from './CurrentLocationItem';
import CurrentUserItem from './CurrentUserItem';
import FavoritesItem from './FavoritesItem';
import FAQItem from './FAQItem';
import SearchItem from './SearchItem';
import BlogItem from './BlogItem';
import LanguageChanger from './LanguageChanger';

export {
    NavBarItem,
    NavBarMenu,
    NavBarPull,
    CurrentLocationItem,
    CurrentUserItem,
    FavoritesItem,
    FAQItem,
    SearchItem,
    BlogItem,
    LanguageChanger,
};

const NavBarWrapper = ({className: baseClassName, fixed: isFixed, children, ...props}) => {
    const fixed = isFixed === true ? 'top' : isFixed;
    const className = classNames(baseClassName, fixed && `is-fixed-${fixed}`);

    useBodyClass(fixed && `has-navbar-fixed-${fixed}`);

    return (
        <NavBar className={className} {...props}>
            {children}
        </NavBar>
    );
};

NavBarWrapper.defaultProps = {
    fixed: null,
};

export default NavBarWrapper;
