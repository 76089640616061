import {Suspense} from 'react';

import {useBodyClass} from 'selectra/hooks/dom';

import {PageErrorBoundary} from 'components/ErrorBoundary';
import EyewearFinder from 'components/EyewearFinder';
import ChunkFallback from 'components/ChunkFallback';

export const PageBoundary = ({name, PageContent, children, ...props}) => (
    <PageErrorBoundary>
        {children}
        <Suspense fallback={<ChunkFallback />}>
            <PageContent {...props} page={name} />
        </Suspense>
    </PageErrorBoundary>
);

const Page = ({className, name, eyewearFinder = false, PageContent, ...props}) => {
    useBodyClass(`${className || name}-page`);

    return (
        <PageBoundary name={name} PageContent={PageContent} {...props}>
            {eyewearFinder && <EyewearFinder />}
        </PageBoundary>
    );
};

export default Page;
