import classNames from 'classnames';

const NavBarItem = ({Element, className, children, ...props}) => (
    <Element className={classNames('menu-item', className)} {...props}>
        {children}
    </Element>
);

NavBarItem.defaultProps = {
    Element: 'a',
};

export default NavBarItem;