import IconClose from 'images/icon-close.svg.react';

import {Fragment, useCallback, useState} from 'react';

import _ from 'utils/i18n';
import {getJSON, setJSON} from 'utils/localStorage';

import {useURL} from 'selectra/hooks';

import {BaseBanner, StorageKey as ConsentedKey} from 'components/CookieBanner';

const Title = _('Close');

const StorageKey = 'tracking-acknowledged';
const Identifier = 'tracking-message';

const getInitialState = () => {
    const consented = getJSON(ConsentedKey);

    if (consented) return true;

    return getJSON(StorageKey);
};

const TrackingMessage = ({children}) => {
    const url = useURL('privacy-policy');
    const [acknowledged, setAcknowledged] = useState(getInitialState);

    const handleClick = useCallback(
        () => {
            setAcknowledged(true);
            setJSON(StorageKey, true);
        },
        [setAcknowledged],
    );

    return (
        <Fragment>
            {children}
            {acknowledged || (
                <BaseBanner className={Identifier} identifier={Identifier} context={{url}}>
                    <button title={Title} onClick={handleClick}>
                        <IconClose />
                    </button>
                </BaseBanner>
            )}
        </Fragment>
    );
};

export default TrackingMessage;
