import {Link} from 'react-router-dom';

import _ from 'utils/i18n';

import {useURL} from 'selectra/hooks';

import NavBarItem from '../NavBarItem';

const Title = _('Optician Login');

const CurrentUserItem = ({name, email, is_authenticated: isAuthenticated}) => {
    const url = useURL(isAuthenticated ? 'user-profile' : 'login');

    return (
        <NavBarItem Element={Link} to={url} className="user">
            {isAuthenticated ? (name || email) : Title}
        </NavBarItem>
    );
};

export default CurrentUserItem;
