import {useCallback, useEffect, useRef} from 'react';

const PlaceType = {
    Locality: 'locality',
};

export const getLocationData = place => {
    const {
        geometry,
        formatted_address: label,
        types = [PlaceType.Locality],
    } = place;

    if (geometry) {
        const {location} = geometry;
        const latitude = location.lat();
        const longitude = location.lng();
        const isCity = types.includes(PlaceType.Locality);

        return {latitude, longitude, isCity, label};
    }
};

export const useAutocomplete = onPlaceChanged => {
    const inputRef = useRef(null);
    const autocompleteRef = useRef(null);

    const handlePlaceChanged = useCallback(
        () => {
            const place = autocompleteRef.current.getPlace();

            return onPlaceChanged(place, inputRef.current.value);
        },
        [onPlaceChanged],
    );

    useEffect(
        () => {
            const {maps: GoogleMaps} = global.google;

            const autocomplete = autocompleteRef.current = new GoogleMaps.places.Autocomplete(inputRef.current, {
                fields: ['formatted_address', 'geometry', 'type'],
            });

            const identifier = autocomplete.addListener('place_changed', handlePlaceChanged);

            return () => {
                GoogleMaps.event.removeListener(identifier);
                autocompleteRef.current = null;
            };
        },
        [handlePlaceChanged, inputRef],
    );

    return inputRef;
};
