import {Link} from 'react-router-dom';

import _ from 'utils/i18n';
import {buildURL} from 'utils/url';

import NavBarItem from './NavBarItem';

const Title = _('Info');
const URL = buildURL('faq');

const FAQItem = () => (
    <NavBarItem Element={Link} to={URL}>
        {Title}
    </NavBarItem>
);

export default FAQItem;
