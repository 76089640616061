const defaultGetKey = obj => obj.id;

export const normalize = (objects, current = null, getKey = defaultGetKey) => {
    const data = {};

    data.ids = objects.map(obj => {
        const key = getKey(obj);

        data[key] = current ? merge(current, obj, getKey) : obj;

        return key;
    });

    return data;
};

export const omit = (object, key) => {
    const copy = {...object};

    delete copy[key];

    return copy;
};

export const merge = (objects, object, getKey = defaultGetKey) => {
    const key = getKey(object);

    return key in objects ? {...objects[key], ...object} : object;
};

export const isFunction = value => typeof value === 'function';

export const isNumber = value => typeof value === 'number';

export const isObject = value => typeof value === 'object';

export const isString = value => typeof value === 'string';

export const keys = obj => Object.keys(obj);

export const len = obj => keys(obj).length;

export const hasKeys = obj => len(obj) > 0;

export const isEmpty = obj => len(obj) === 0;

export const entries = Object.entries || (obj => keys(obj).map(key => [key, obj[key]]));
