import {useCallback, useRef, useState} from 'react';
import cN from 'classnames';

import {useOnClickOutside} from 'selectra/hooks';

import CheckGoogleMaps from 'components/GoogleMap';

import LocationMap from './LocationMap';
import PlacesWidget from './PlacesWidget';

const LocationWidget = ({hidden, onClose}) => {
    const widgetRef = useRef(null);
    const [viewport, setViewPort] = useState(null);

    useOnClickOutside(widgetRef, hidden ? null : onClose);

    const handlePlace = useCallback(place => {
        const {viewport} = place.geometry;
        if (viewport) setViewPort(viewport);
    }, [setViewPort]);

    return (
        <div className={cN('notification location-widget', {hidden})} ref={widgetRef}>
            <div className="notification-inner">
                <div className="notification-content">
                    <CheckGoogleMaps enabled={hidden === false}>
                        <div className="location-widget-header">
                            <PlacesWidget onPlace={handlePlace} />
                        </div>
                        {hidden || <LocationMap viewport={viewport} />}
                    </CheckGoogleMaps>
                </div>
            </div>
        </div>
    );
};

export default LocationWidget;
