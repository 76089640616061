import {createContext, useCallback, useEffect, useMemo} from 'react';

import {useGoogleMap} from './hooks';

export const GoogleMapContext = createContext(null);

const GoogleMap = ({children, id, options: {center, zoom}, viewport}) => {
    const [map, container, onInitialized] = useGoogleMap(id);

    const panTo = useCallback(center => onInitialized(map => map.panTo(center)), [onInitialized]);
    const setZoom = useCallback(zoom => onInitialized(map => map.setZoom(zoom)), [onInitialized]);
    const fitBounds = useCallback(bounds => onInitialized(map => map.fitBounds(bounds)), [onInitialized]);

    useEffect(() => setZoom(zoom), [zoom, setZoom]);
    useEffect(() => panTo(center), [center, panTo]);
    useEffect(() => { if (viewport) fitBounds(viewport); }, [viewport, fitBounds]);

    const addControl = useCallback((node, position) => { if (map) map.controls[position].push(node); }, [map]);

    const removeControl = useCallback(
        (node, position) => {
            if (!map) return;

            const controls = map.controls[position];
            const index = controls.getArray().indexOf(node);

            controls.removeAt(index);
        },
        [map],
    );

    const addMarker = useCallback(marker => { if (map) marker.setMap(map); }, [map]);
    const removeMarker = useCallback(marker => marker.setMap(null), []);

    const context = useMemo(
        () => ({map: {current: map}, addControl, removeControl, addMarker, removeMarker}),
        [map, addControl, removeControl, addMarker, removeMarker],
    );

    return (
        <GoogleMapContext.Provider value={context}>
            <div className="google-map-container" ref={container} />
            {map && children}
        </GoogleMapContext.Provider>
    );
};

export default GoogleMap;
