import {useCallback, useEffect, useState} from 'react';

import {useCurrentLocation, useSwitchLocation} from 'selectra/hooks/location';

import {GoogleMap, Control, Marker, MapStyles} from 'components/GoogleMap';
import Icon from 'components/Icon';

const BERLIN = {
    city: 'Berlin',
    countryCode: 'DE',
    latitude: 52.52,
    longitude: 13.4,
};

const getCenter = location => {
    let center = {lat: BERLIN.latitude, lng: BERLIN.longitude};

    if (location.latitude && location.longitude) {
        center = {lat: location.latitude, lng: location.longitude};
    }

    return center;
};

const LocationMap = ({viewport}) => {
    const location = useCurrentLocation();
    const switchLocation = useSwitchLocation();
    const [center, setCenter] = useState(() => getCenter(location));

    useEffect(() => setCenter(getCenter(location)), [location]);

    const handleLocate = useCallback(
        () => navigator.geolocation.getCurrentPosition(position => switchLocation(position.coords)),
        [switchLocation],
    );

    const handleMarkerDrag = useCallback(
        ({latLng}) => {
            const latitude = latLng.lat();
            const longitude = latLng.lng();

            switchLocation({latitude, longitude});
        },
        [switchLocation],
    );

    return (
        <GoogleMap
            id="location-map"
            options={{
                center,
                zoom: 8,
                streetViewControl: false,
                fullscreenControl: false,
                mapTypeControl: false,
                styles: MapStyles,
            }}
            viewport={viewport}
        >
            <Control position={global.google.maps.ControlPosition.RIGHT_BOTTOM}>
                <button className="gm-control-button" onClick={handleLocate}>
                    <Icon>gps_fixed</Icon>
                </button>
            </Control>
            <Marker position={center} draggable onDrag={handleMarkerDrag} />
        </GoogleMap>
    );
};

export default LocationMap;
