import {Fragment} from 'react';
import {Link} from 'react-router-dom';

import _ from 'utils/i18n';
import {buildURL} from 'utils/url';

import {Brands, Eyeglasses, Opticians, Sunglasses} from 'selectra/urls';

import NavBar, {
    NavBarMenu,
    NavBarItem,
    BlogItem,
    CurrentLocationItem,
    CurrentUserItem,
    FavoritesItem,
    FAQItem,
    SearchItem,
    LanguageChanger,
} from 'main-site/components/NavBar';

const MainItems = [
    {url: buildURL(Sunglasses), label: _('Sunglasses')},
    {url: buildURL(Eyeglasses), label: _('Eyeglasses')},
    {url: buildURL(Brands), label: _('Brands')},
    {url: buildURL(Opticians), label: _('Find Opticians')},
];

const Header = () => (
    <Fragment>
        <NavBar fixed burger>
            <NavBarMenu>
                <div className="left">
                    {MainItems.map(({url, label}) => (
                        <NavBarItem Element={Link} key={url} to={url}>
                            {label}
                        </NavBarItem>
                    ))}
                    <BlogItem />
                </div>
                <div className="right">
                    <CurrentUserItem />
                    <FAQItem />
                    <SearchItem />
                    <CurrentLocationItem />
                    <FavoritesItem />
                </div>
                <LanguageChanger />
            </NavBarMenu>
        </NavBar>
        <div className="navbar-footer">
            <div className="section" />
        </div>
    </Fragment>
);

export default Header;
