var React = require('react');

function IconSearchSvg (props) {
    return React.createElement("svg",props,React.createElement("path",{"d":"M46.25 49.25l24 24m-14-45a28 28 0 0 1-28 28 28 28 0 0 1-28-28 28 28 0 0 1 28-28 28 28 0 0 1 28 28z"}));
}

IconSearchSvg.defaultProps = {"className":"icon search","viewBox":"0 0 70.427 73.427"};

module.exports = IconSearchSvg;

IconSearchSvg.default = IconSearchSvg;
