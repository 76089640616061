import {useCallback, useState} from 'react';

import _ from 'utils/i18n';
import {stopPropagation} from 'utils/event';

import {UserLocationSource} from 'selectra/constants';
import {useIsMounted} from 'selectra/hooks/lifecycle';
import {useSwitchLocation} from 'selectra/hooks/location';

import CloseButton from 'components/CloseButton';

const Message = _('Please let us locate you');
const Label = _('OK');

export const Available = Boolean(global.navigator && navigator.geolocation);
export const getShouldShowUserLocator = source => Available && UserLocationSource.Inaccurate.includes(source);

const UserLocator = () => {
    const isMounted = useIsMounted();
    const [show, setShow] = useState(true);
    const switchLocation = useSwitchLocation();

    const hide = useCallback(
        event => {
            stopPropagation(event);
            setShow(false);
        },
        [setShow],
    );

    const onClick = useCallback(
        event => {
            hide(event);

            navigator.geolocation.getCurrentPosition(position => {
                if (isMounted.current) switchLocation(position.coords);
            });
        },
        [switchLocation, hide, isMounted],
    );

    return show && (
        <div className="notification">
            <div className="notification-inner">
                <div className="notification-content">
                    <CloseButton onClick={hide} />
                    <p>{Message}</p>
                    <div className="btn-area">
                        <button className="is-primary" onClick={onClick}>{Label}</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserLocator;
