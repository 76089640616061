import IconFav from 'images/icon-heart.svg.react';

import {Link} from 'react-router-dom';

import {useURL} from 'selectra/hooks';
import {Favorites} from 'selectra/urls';

import _ from 'utils/i18n';

import NavBarItem from './NavBarItem';

const Title = _('Favorites');

const FavoritesItem = () => {
    const url = useURL(Favorites);

    return (
        <NavBarItem Element={Link} to={url} title={Title}>
            <IconFav />
            <span className="mobile-only">{Title}</span>
        </NavBarItem>
    );
};

export default FavoritesItem;
