/* eslint-disable max-classes-per-file */

import 'core-js/stable';
import 'isomorphic-fetch';
import 'regenerator-runtime';

import {extractFiles} from 'extract-files';

import * as Message from 'selectra/messages';

import {NamedError} from './error';
import {isString} from './object';

export const CSRFMiddlewareTokenHeaderName = 'X-CSRFToken';

export class NetworkError extends NamedError {

    static name = 'NetworkError';

}

class ResponseError extends NamedError {

    constructor(response, message) {
        super(message);
        this.response = response;
    }

}

export class DecodeError extends ResponseError {

    static name = 'DecodeError';

}

const getMessage = (response, data) => {
    let message;

    if (isString(data)) message = data;

    else {
        try {
            message = JSON.stringify(data);
        } catch (error) {
            message = response.statusText;
        }
    }

    return `[${response.status}] ${message}`;
};

export class HttpError extends ResponseError {

    static name = 'HttpError';

    constructor(response, data) {
        super(response, getMessage(response, data));
        this.code = response.status;
        this.data = data;
    }

}

const DataHandlers = {
    text: response => response.text(),
    json: response => response.json(),
    pdf: response => response.blob(),
};

export const getHandler = (response, handlers = DataHandlers) => {
    const contentType = response.headers.get('Content-Type');

    if (contentType === null) throw new DecodeError(response, 'Missing content type header.');

    const [mimeType] = contentType.split('; ');
    const [type, subtype] = mimeType.split('/');
    const handler = handlers[mimeType] || handlers[subtype] || handlers[type] || null;

    if (handler === null) throw new DecodeError(response, `No data handler found for content type ${mimeType}.`);

    return handler;
};

export const getErrorStatusCode = error => {
    if (error instanceof HttpError) return error.code;
    if (error instanceof DecodeError) return error.response.status;

    return null;
};

export const isNotFound = error => getErrorStatusCode(error) === 404;
export const isBadRequest = error => getErrorStatusCode(error) === 400;

const DefaultClientErrors = {};

export const getClientErrors = error => (
    (error instanceof HttpError && error.code === 400) ? error.data : DefaultClientErrors
);

export const getErrorMessage = error => {
    if (error instanceof NetworkError) return Message.NetworkError;

    if (error instanceof HttpError) {
        switch (error.code) {
            case 400:
                return Message.ClientError;
            case 500:
                return Message.ServerError;
            case 502:
            case 503:
                return Message.Maintenance;
            default:
                return Message.getGenericErrorMessage(error.response.statusText || error.code);
        }
    }

    return Message.getGenericErrorMessage(error);
};

const getBody = data => {
    if (data instanceof FormData) return {body: data};

    const {clone, files} = extractFiles(data);

    let body;
    let contentType = null;

    if (files.size) {
        body = new FormData();
        body.append('data', JSON.stringify(clone));
        files.forEach(([path], file) => {
            body.append(path, file, file.name);
        });
    } else {
        body = JSON.stringify(data);
        contentType = 'application/json';
    }

    return {body, contentType};
};

export const getJSONOptions = (data, CSRFMiddlewareToken, method = 'POST') => {
    const {body, contentType} = getBody(data);

    const headers = {
        [CSRFMiddlewareTokenHeaderName]: CSRFMiddlewareToken,
    };

    if (contentType) {
        headers['Content-Type'] = contentType;
    }

    return {
        method,
        credentials: 'same-origin',
        body,
        headers,
    };
};

export default async (url, options, onCSRFMiddlewareTokenChange) => {
    const request = fetch(url, options);

    let response;

    try {
        response = await request;
    } catch (error) {
        throw new NetworkError(error);
    }

    const CSRFMiddlewareToken = response.headers.get(CSRFMiddlewareTokenHeaderName);

    if (CSRFMiddlewareToken && onCSRFMiddlewareTokenChange) onCSRFMiddlewareTokenChange(CSRFMiddlewareToken);

    if (response.status === 204) return null;

    const handler = getHandler(response);

    let data;

    try {
        data = await handler(response);
    } catch (error) {
        throw new DecodeError(response, error);
    }

    if (response.ok) return data;

    throw new HttpError(response, data);
};
