import {Link} from 'react-router-dom';

import _ from 'utils/i18n';

import {BlogActive, BlogURL} from 'selectra/constants';
import {useURL} from 'selectra/hooks';

import NavBarItem from './NavBarItem';

const Title = _('Blog');

const BlogItem = () => {
    const url = useURL('blog');
    const props = {};

    if (BlogActive) {
        props.Element = Link;
        props.to = url;
    } else {
        props.href = BlogURL;
    }

    return (
        <NavBarItem {...props}>
            {Title}
        </NavBarItem>
    );
};

export default BlogItem;
