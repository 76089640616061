/* global InitialLocation */

import {useCallback} from 'react';
import {useQueryClient} from 'react-query';
import {atom, useAtomValue, useSetAtom} from 'jotai';

import {buildAPIURL} from 'utils/url';
import {addLocation} from 'utils/user-location';

import {useMutation} from './react-query';

export const makeCity = ({city, country, country_code: countryCode, ...location}) => ({
    ...location,
    name: city,
    country: {
        name: country,
        code: countryCode,
    },
});

const Endpoint = buildAPIURL('location');

const locationAtom = atom({
    ...InitialLocation,
    source: 'initial',
    fetched: 0,
});

export const useSetLocation = () => {
    const setLocation = useSetAtom(locationAtom);

    return useCallback(
        location => setLocation({
            ...location,
            fetched: Date.now(),
        }),
        [setLocation],
    );
};

export const useSwitchLocation = () => {
    const queryClient = useQueryClient();
    const setLocation = useSetLocation();
    const {mutate} = useMutation(Endpoint, 'POST', {
        onSuccess(data) {
            setLocation(data);

            const {is_city: isCity = true} = data;

            if (!isCity) addLocation(queryClient, makeCity(data));
        },
    });

    return useCallback(
        ({latitude, longitude, isCity = false}) => {
            const data = {latitude, longitude};

            if (isCity) data.is_city = true;

            mutate(data);
        },
        [mutate],
    );
};

export const useCurrentLocation = () => useAtomValue(locationAtom);

export const useLocationTimestamp = () => {
    const {fetched} = useCurrentLocation();

    return fetched;
};
