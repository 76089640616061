import {useCallback, useState} from 'react';
import range from 'lodash/range';

import _ from 'utils/i18n';

import {useBodyClass} from 'selectra/hooks/dom';

const ToggleTitle = _('Toggle Menu');

export const DefaultActiveClassName = 'mobile-menu-is-active';

export const useActive = initial => {
    const [active, setActive] = useState(initial);
    const toggleActive = useCallback(() => setActive(active => !active), [setActive]);

    return [active, toggleActive];
};

export const useBurgerMenu = (className = DefaultActiveClassName) => {
    const [active, toggleActive] = useActive(false);

    useBodyClass(active && className);

    return [active, toggleActive];
};

export const NavBarToggleButton = ({onClick, children}) => (
    <a className="navbar-toggle" role="button" aria-label="menu" aria-expanded="false" onClick={onClick}>
        {children}
        <span className="a11y-hidden">{ToggleTitle}</span>
    </a>
);

const NavBarToggle = ({stripes = 3, onClick}) => (
    <NavBarToggleButton onClick={onClick}>
        {range(stripes).map(stripe => <span key={stripe} aria-hidden="true" />)}
    </NavBarToggleButton>
);

export default NavBarToggle;
