import IconSearch from 'images/icon-search.svg.react';

import {Link} from 'react-router-dom';

import {buildURL} from 'utils/url';

import {useProductType} from 'selectra/hooks/products';

import {TypeSlugs} from 'components/ProductURLContext';

import NavBarItem from './NavBarItem';

const SearchItem = () => {
    const productType = useProductType();

    if (productType === null) return null;

    const productTypeSlug = TypeSlugs[productType];
    const baseURL = buildURL(productTypeSlug);
    const url = `${baseURL}?search`;

    return (
        <NavBarItem Element={Link} to={url}>
            <IconSearch />
        </NavBarItem>
    );
};

export default SearchItem;
