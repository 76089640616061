import {lazy as loadable} from '@loadable/component';

import BasePage from 'components/Page';

const PageContent = loadable(props => import(`./${props.page}`));
const Page = props => <BasePage {...props} PageContent={PageContent} />;

export const BrandDetailPage = props => <Page {...props} name="brand-detail" eyewearFinder />;
export const BrandPostPage = props => <Page {...props} name="brand-post" />;
export const ContactStoresPage = props => <Page {...props} name="contact-stores" />;
export const FavoritesPage = props => <Page {...props} name="favorites" eyewearFinder />;
export const ProductDetailPage = props => <Page {...props} name="product-detail" eyewearFinder />;
export const ProductRequestPage = props => <Page {...props} name="product-request" className="store-contact" />;
export const StoreListPage = props => <Page {...props} name="store-list" />;
export const StoreDetailPage = props => <Page {...props} name="store-detail" eyewearFinder />;
export const StoreContactPage = props => <Page {...props} name="store-contact" />;
export const ImprintPage = props => <Page {...props} name="imprint" eyewearFinder />;
export const PrivacyPolicyPage = props => <Page {...props} name="privacy-policy" eyewearFinder />;
