import {createContext, useContext} from 'react';

import _ from 'utils/i18n';

import UserQuery from 'components/UserQuery';

export const AnonymousUser = {
    identifier: null,
    email: 'anonymous@favr.com',
    name: _('Anonymous'),
    phone: null,
    brand: null,
    store: null,
    is_anonymous: true,
    is_authenticated: false,
    is_superuser: false,
};

const UserContext = createContext(AnonymousUser);

export const useUser = (authenticated = null) => {
    const user = useContext(UserContext);
    const {
        is_authenticated: isAuthenticated,
        is_anonymous: isAnonymous,
    } = user;

    switch (authenticated) {
        case true:
            return isAuthenticated ? user : null;
        case false:
            return isAnonymous ? user : null;
        default:
            return user;
    }
};

export const useUserStore = () => useContext(UserContext).store || null;

const UserProvider = ({children}) => (
    <UserQuery placeholderData={AnonymousUser}>
        {user => (
            <UserContext.Provider value={user}>
                {children}
            </UserContext.Provider>
        )}
    </UserQuery>
);

export default UserProvider;
