import {useCallback, useEffect} from 'react';

import {useCurrentLocation, useSwitchLocation} from 'selectra/hooks/location';

import {getLocationData, useAutocomplete} from 'selectra/hooks/google-places';

const PlacesWidget = ({onPlace}) => {
    const {city, country} = useCurrentLocation();
    const switchLocation = useSwitchLocation();

    const handlePlaceChange = useCallback(place => {
        const location = getLocationData(place);

        if (location) {
            switchLocation(location);
            onPlace(place);
        }
    }, [onPlace, switchLocation]);

    const inputRef = useAutocomplete(handlePlaceChange);

    useEffect(() => {
        if (city || country) inputRef.current.value = country ? `${city}, ${country}` : city;
    }, [city, country, inputRef]);

    return <input className="input" ref={inputRef} type="text" />;
};

export default PlacesWidget;
