import {createContext, useContext, useEffect, useState} from 'react';

import {ProductType} from 'selectra/constants';

const ProductTypeContext = createContext(null);

export const useProductType = (defaultType = ProductType.Eyeglasses) => {
    const {productType} = useContext(ProductTypeContext);

    return productType || defaultType;
};

export const useStoreProductType = productType => {
    const {setProductType} = useContext(ProductTypeContext);

    useEffect(
        () => {
            setProductType(productType);
        },
        [productType, setProductType],
    );
};

const ProductTypeProvider = ({initialValue = null, children}) => {
    const [productType, setProductType] = useState(initialValue);
    const value = {productType, setProductType};

    return (
        <ProductTypeContext.Provider value={value}>
            {children}
        </ProductTypeContext.Provider>
    );
};

export default ProductTypeProvider;
