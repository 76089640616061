import {useCurrentLocation, useSetLocation} from 'selectra/hooks/location';

import Query from 'components/Query';

import CurrentLocationItem from './CurrentLocationItem';

const CurrentLocationItemWrapper = () => {
    const setLocation = useSetLocation();
    const currentLocation = useCurrentLocation();
    const options = {
        initialData: currentLocation,
        refetchOnWindowFocus: false,
        onSuccess(data) {
            setLocation(data);
        },
    };

    return (
        <Query queryKey="location" {...options}>
            {() => <CurrentLocationItem {...currentLocation} />}
        </Query>
    );
};

export default CurrentLocationItemWrapper;
