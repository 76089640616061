import {lazy as loadable} from '@loadable/component';

import {ProductType} from 'selectra/constants';

import {
    At,
    Brands,
    Contact,
    Eyeglasses,
    Favorites,
    Highlights,
    In,
    Locations,
    Opticians,
    Products,
    Sunglasses,
} from 'selectra/urls';

import BasePage from 'components/Page';
import {url, include} from 'components/URLConfig';

import {
    BrandDetailPage,
    BrandPostPage,
    ProductDetailPage,
    ProductRequestPage,
    StoreDetailPage,
    StoreContactPage,
    ContactStoresPage,
    FavoritesPage,
    ImprintPage,
    PrivacyPolicyPage,
} from 'selectra/pages';

import StoreListPage from './store-list';

const PageContent = loadable(props => import(`./${props.page}`));
const Page = props => <BasePage {...props} PageContent={PageContent} />;

const StartPage = props => <Page {...props} name="start" eyewearFinder />;
const LoginPage = props => <Page {...props} name="login" eyewearFinder />;
const LogoutPage = props => <Page {...props} name="logout" eyewearFinder />;
const PasswordResetPage = props => <Page {...props} name="password-reset" eyewearFinder />;
const UserProfilePage = props => <Page {...props} name="user-profile" />;
const StoreProductPostAddPage = props => <Page {...props} name="store-product-post-add" />;
const StoreProductPostEditPage = props => <Page {...props} name="store-product-post-edit" />;
const PaymentPage = props => <Page {...props} name="payment" />;
const BillingInformationChangePage = props => <Page {...props} name="billing-information-change" />;
const SubscriptionChangePage = props => <Page {...props} name="subscription-change" />;
const ProductListPage = props => <Page {...props} name="product-list" eyewearFinder />;
const EyeglassesPage = props => <ProductListPage {...props} type={ProductType.Eyeglasses} />;
const SunglassesPage = props => <ProductListPage {...props} type={ProductType.Sunglasses} />;
const BrandListPage = props => <Page {...props} name="brand-list" />;
const StoreRegistrationPage = props => <Page {...props} name="store-registration" />;
const StoreRegistrationPlanSelectionPage = props => <Page {...props} name="store-registration-plan-selection" />;
const CountryListPage = props => <Page {...props} name="country-list" />;
const ProductPostPage = props => <Page {...props} name="product-post" eyewearFinder />;
const StorePostPage = props => <Page {...props} name="store-post" eyewearFinder />;
const TokenExpiredPage = props => <Page {...props} name="token-expired" />;
const BlogPage = props => <Page {...props} name="blog" />;
const FAQPage = props => <Page {...props} name="faq" />;

export default [
    url('', StartPage),
    url('login/', LoginPage),
    url('logout/', LogoutPage),
    url('password-reset/', PasswordResetPage, {exact: false}),
    url('user-profile/', UserProfilePage, {auth: true, exact: false}),
    include('store-profile/', [
        include('product-post/', [
            url('add/', StoreProductPostAddPage, {auth: true}),
            url(':id/', StoreProductPostEditPage, {auth: true}),
        ]),
        url('billing/information/change/', BillingInformationChangePage, {auth: true}),
        include('payment/', [
            url(':id/change/', SubscriptionChangePage, {auth: true}),
            include(':plan/', [
                url('', PaymentPage, {auth: true}),
                url(':method/', PaymentPage, {auth: true}),
            ]),
        ]),
    ]),
    url(Favorites, FavoritesPage),
    url(`${Eyeglasses}/:filter1?/:filter2?/:filter3?/`, EyeglassesPage),
    url(`${Sunglasses}/:filter1?/:filter2?/:filter3?/`, SunglassesPage),
    include(Brands, [
        url('', BrandListPage),
        include(':brandSlug/:brandID/', [
            url('', BrandDetailPage),
            url(':brandPostSlug/:brandPostID/', BrandPostPage),
            include(Opticians, [
                url('', StoreListPage),
                url(Contact, ContactStoresPage),
                include(In, [
                    include(':citySlug/:cityID/', [
                        url('', StoreListPage),
                        url(Contact, ContactStoresPage),
                    ]),
                ]),
                include(At, [
                    include(':lat/:lng/', [
                        url('', StoreListPage),
                        url(Contact, ContactStoresPage),
                    ]),
                ]),
            ]),
            include([Eyeglasses, Sunglasses], [
                include(':productSlug/:productID/', [
                    url('', ProductDetailPage),
                    include(':variantSlug/:variantID/', [
                        url('', ProductDetailPage),
                        url(Contact, ProductRequestPage),
                    ]),
                ]),
            ]),
        ]),
    ]),
    include(Opticians, [
        url('', StoreListPage),
        url(Contact, ContactStoresPage),
        url('register/plan/', StoreRegistrationPlanSelectionPage),
        url('register/', StoreRegistrationPage, {exact: false}),
        include(In, [
            include(':citySlug/:cityID/', [
                url('', StoreListPage),
                url(Contact, ContactStoresPage),
                include(':storeSlug/:storeID/', [
                    url('', StoreDetailPage),
                    url(Contact, StoreContactPage),
                ]),
            ]),
        ]),
        include(At, [
            include(':lat/:lng/', [
                url('', StoreListPage),
                url(Contact, ContactStoresPage),
            ]),
        ]),
    ]),
    url(Locations, CountryListPage),
    include(Highlights, [
        include(Products, [
            url(':slug/:id/', ProductPostPage),
        ]),
        include(Opticians, [
            url(':slug/:id/', StorePostPage),
        ]),
    ]),
    url('imprint', ImprintPage),
    url('privacy-policy', PrivacyPolicyPage),
    url('faq', FAQPage),
    url('token-expired', TokenExpiredPage),
    url('blog', BlogPage),
];
