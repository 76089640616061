import {useMutation as useBaseMutation, useQueryClient} from 'react-query';

import getResult, {getErrorMessage, getJSONOptions} from 'utils/fetch';

import {useHasFinishedInitialFetching} from 'components/InitialPageLoad';

import {useCSRFMiddlewareToken, useUpdateCSRFMiddlewareToken} from './csrf';
import {useAddMessage, Intent} from './messages';

export {useHasFinishedInitialFetching};

export const useMutation = (url, method, mutationOptions) => {
    const addMessage = useAddMessage();
    const CSRFMiddlewareToken = useCSRFMiddlewareToken();
    const updateCSRFMiddlewareToken = useUpdateCSRFMiddlewareToken();
    const {onError, ...options} = mutationOptions || {};
    const mutationFn = data => getResult(
        url,
        getJSONOptions(data, CSRFMiddlewareToken, method),
        CSRFMiddlewareToken => updateCSRFMiddlewareToken(CSRFMiddlewareToken),
    );

    return useBaseMutation([url, method], mutationFn, {
        ...options,
        onError(error, ...args) {
            const message = onError && onError(error, ...args);

            if (message === false) return;

            addMessage({content: message || getErrorMessage(error), intent: Intent.Error}, true);
        },
    });
};

const getQueryKey = (endpoint, id, action, params) => [endpoint, id, action, params].filter(Boolean);

const DefaultConfig = {useID: true, action: null, params: null};

export const useUpdateQueryData = (endpoint, {useID = true, action = null, params = null} = DefaultConfig) => {
    const queryClient = useQueryClient();

    return obj => queryClient.setQueryData(
        getQueryKey(endpoint, useID ? obj.id : null, action, params),
        data => data ? {...data, ...obj} : obj,
    );
};

export const useBulkUpdateQueryData = (endpoint, {useID = true, action = null, params = null} = DefaultConfig) => {
    const updateQueryData = useUpdateQueryData(endpoint, {useID, action, params});

    return objs => objs.forEach(obj => {
        updateQueryData(obj);
    });
};
