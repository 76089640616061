import {useCallback, useState} from 'react';

import {getJSON, setJSON} from 'utils/localStorage';

import BaseBanner from './BaseBanner';
import CookieBanner from './CookieBanner';

export {BaseBanner};

export const StorageKey = 'tracking-consent';

const getInitialState = () => getJSON(StorageKey);

const useConsent = () => {
    const [consented, setConsented] = useState(getInitialState);

    const changeConsented = useCallback(
        value => {
            setConsented(value);
            setJSON(StorageKey, value);
        },
        [setConsented],
    );

    const consent = useCallback(() => changeConsented(true), [changeConsented]);
    const dissent = useCallback(() => changeConsented(false), [changeConsented]);

    return {consented, consent, dissent};
};

const CookieConsent = ({children = null}) => {
    const {consented, ...handler} = useConsent();

    return consented === null ? <CookieBanner {...handler} /> : children;
};

export default CookieConsent;
