import UserQuery from 'components/UserQuery';

import TrackingMessage from './TrackingMessage';

const TrackingMessageGuard = props => (
    <UserQuery>
        {user => <TrackingMessage user={user} {...props} />}
    </UserQuery>
);

export default TrackingMessageGuard;
