import {useQuery} from 'react-query';

import getLoader, {ID} from 'utils/gmaps';
import _ from 'utils/i18n';

import QueryResult from 'components/QueryResult';

import {useGoogleMap} from './hooks';

import MapStyles from './map-styles.json';

import GoogleMap from './GoogleMap';
import Control from './Control';
import Marker from './Marker';

const Message = _('Google Maps is not loaded. Please check your ad blocker and reload the page.');

export {
    MapStyles,
    GoogleMap,
    Control,
    Marker,
    useGoogleMap,
};

const GenericErrorMessage = () => <p className="warning">{Message}</p>;

const Components = {
    generic: GenericErrorMessage,
};

const CheckGoogleMaps = ({id = ID, enabled = false, children}) => {
    const queryFn = getLoader(id);
    const query = useQuery({
        queryFn,
        queryKey: id,
        cacheTime: Infinity,
        staleTime: Infinity,
        enabled,
    });

    return (
        <QueryResult query={query} components={Components}>
            {() => children}
        </QueryResult>
    );
};

export default CheckGoogleMaps;
