import {useCallback, useEffect, useRef, useState} from 'react';

import language from 'utils/language';
import {buildURL} from 'utils/url';

import MapStyles from './map-styles.json';

const MapCache = [];

const DefaultOptions = {
    zoom: 9,
    center: {lat: 52, lng: 13},
    styles: MapStyles,
    mapTypeControl: false,
    fullscreenControl: false,
    streetViewControl: false,
};

const LogURL = buildURL(language, 'log', 'map');

const getMap = node => {
    if (window.navigator && window.navigator.sendBeacon) {
        navigator.sendBeacon(LogURL);
    }

    return new global.google.maps.Map(node, DefaultOptions);
};

export const useGoogleMap = (id = null) => {
    const {maps: GMaps} = global.google;
    const [map, setMap] = useState(null);
    const container = useRef(null);

    useEffect(
        () => {
            const containerNode = container.current;

            let node;
            let map = null;

            if (MapCache.length) {
                map = MapCache.pop();
                node = map.getDiv();
            } else {
                node = document.createElement('div');
            }

            node.id = id || '';
            node.classList.add('google-map');

            containerNode.appendChild(node);
            setMap(map = map || getMap(node));

            return () => {
                containerNode.removeChild(node);
                MapCache.push(map);
                setMap(null);
            };
        },
        [id, container],
    );

    const onInitialized = useCallback(callback => {
        if (map) {
            if (map.renderingType === GMaps.RenderingType.UNINITIALIZED) {
                GMaps.event.addListenerOnce(map, 'renderingtype_changed', () => callback(map));
            } else {
                callback(map);
            }
        }
    }, [map]);

    return [map, container, onInitialized];
};
