import UserQuery from 'components/UserQuery';
import {AnonymousUser} from 'components/UserContext';

import CurrentUserItem from './CurrentUserItem';

const CurrentUserItemWrapper = () => (
    <UserQuery placeholderData={AnonymousUser}>
        {user => <CurrentUserItem {...user} />}
    </UserQuery>
);

export default CurrentUserItemWrapper;
