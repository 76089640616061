import IconLocation from 'images/icon-location.svg.react';

import {useCallback, useState} from 'react';

import LocationWidget from 'main-site/components/LocationWidget';

import NavBarItem from '../NavBarItem';
import UserLocator, {getShouldShowUserLocator} from './UserLocator';

const CurrentLocationItem = ({slug, city, country, source}) => {
    const [showLocationWidget, setLocationWidget] = useState(false);
    const openLocationWidget = useCallback(() => setLocationWidget(true), []);
    const closeLocationWidget = useCallback(() => setLocationWidget(false), []);
    const title = (city && country ? `${city}, ${country}` : city) || slug;

    return (
        <NavBarItem className="current-location" onClick={openLocationWidget} title={title}>
            <IconLocation />
            <span className="mobile-only">{title}</span>
            {getShouldShowUserLocator(source) && <UserLocator />}
            <LocationWidget onClose={closeLocationWidget} hidden={!showLocationWidget} />
        </NavBarItem>
    );
};

CurrentLocationItem.defaultProps = {
    city: null,
    country: null,
};

export default CurrentLocationItem;
