import {pgettext} from 'utils/i18n';

export const Brands = pgettext('url', 'brands');
export const Contact = pgettext('url', 'contact');
export const Eyeglasses = pgettext('url', 'eyeglasses');
export const Sunglasses = pgettext('url', 'sunglasses');
export const Favorites = pgettext('url', 'favorites');
export const Highlights = pgettext('url', 'highlights');
export const Products = pgettext('url', 'products');
export const Opticians = pgettext('url', 'opticians');
export const Locations = pgettext('url', 'locations');
export const In = pgettext('url', 'in');
export const At = pgettext('url', 'at');
export const Pro = pgettext('url', 'pro');
export const Site = pgettext('url', 'site');
export const Find = pgettext('url', 'find');
