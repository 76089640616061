/* global GoogleMapsAPIKey */

import {Loader} from '@googlemaps/js-api-loader';

export const ID = 'google-maps-loader';

const Loaders = {};

const getLoader = id => {
    if (id in Loaders) return Loaders[id];

    const loader = new Loader({
        id,
        apiKey: GoogleMapsAPIKey,
        version: 'weekly',
        libraries: [
            'geometry',
            'places',
        ],
    });

    Loaders[id] = loader.load.bind(loader);

    return getLoader(id);
};

export default getLoader;
