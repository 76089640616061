import {useCallback, useEffect, useRef} from 'react';

import {useAPIURL} from './api';
import {useCaptcha} from './captcha';
import {useCSRFMiddlewareToken} from './csrf';
import {useLanguage} from './language';
import {useAddMessage} from './messages';
import {useMutation} from './react-query';
import {useRouter} from './router';
import {useURL} from './url';

export {
    useAPIURL,
    useCaptcha,
    useLanguage,
    useAddMessage,
    useRouter,
    useCSRFMiddlewareToken,
    useURL,
};

export const useSubmit = (url, values, options) => {
    const valuesRef = useRef(values);
    const {method = 'POST', ...mutationOptions} = options || {};
    const mutation = useMutation(url, method, mutationOptions);
    const {mutate, isLoading} = mutation;
    const handleSubmit = useCallback(
        event => {
            event.preventDefault();

            if (isLoading) return;

            return mutate(valuesRef.current);
        },
        [isLoading, mutate],
    );

    valuesRef.current = values;

    return [handleSubmit, mutation];
};

export const useOnClickOutside = (ref, handler) => {
    useEffect(
        () => {
            const listener = event => {
                // Do nothing if clicking ref's element or descendent elements
                if (!ref.current || ref.current.contains(event.target)) {
                    return;
                }

                if (handler) handler(event);
            };

            document.addEventListener('click', listener, {passive: true});

            return () => {
                document.removeEventListener('click', listener);
            };
        },
        [ref, handler],
    );
};
