import _ from 'utils/i18n';

import {MenuItem} from 'components/Footer';

const Label = _('Sign up to newsletter');
const Link = 'https://55d01af5.sibforms.com/serve/MUIFAPePmmnMjceuW6cZlmOluQ5d50y-d5qriCTswKP0mGmStBhBKvV_ach27ZvYPoXChyL-jXqYFWMYMRgS-8tQav9_8KppzldhnE2U6qCTawwei6xgS6Y434owKI7SDzv-gKMCq3D67HZPykFoV9vmjotR5nQ-U0WLAZiQ_4Gqh3dIxlM4zGG3IK9v4M0iWigh725zKAeSEnML';

const NewsletterSignUp = () => (
    <MenuItem href={Link} target="_blank">
        {Label}
    </MenuItem>
);

export default NewsletterSignUp;
