import IconClose from 'images/icon-close.svg.react';

import _ from 'utils/i18n';

const Title = _('Close');

const CloseButton = ({title = Title, onClick}) => (
    <button className="button is-close" onClick={onClick} title={title}>
        <IconClose />
    </button>
);

export default CloseButton;
